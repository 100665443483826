<template>
  <v-tooltip bottom nudge-right="140" content-class="tooltip-help">
    <template v-slot:activator="{ on, attrs }">
      <div class="tooltip-activator">
        <img
          width="14"
          height="14"
          class="tooltip-icon"
          :src="require('@/assets/icons/question.svg')"
          alt="question"
          v-bind="attrs"
          v-on="on"
        />
      </div>
    </template>
    <slot />
  </v-tooltip>
</template>

<script>
export default {
  name: 'HelpTooltip',
};
</script>

<style lang="sass" scoped>
.tooltip-activator
  position: relative
  top: base-unit(2)
  display: inline-block
</style>
